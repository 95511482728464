import { Box, Grid } from '@material-ui/core';
import React from 'react';

const ProgressReport1 = ({pdf}) => {
  return (
    <div>
      <Box>
        <Grid container spacing={2} style={{ height: "90vh" }}>
          <Grid item xs={12} lg={12}>
            <div style={{ border: "1px solid silver", width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
              <iframe
                style={{ display: "block", width: "100%", height: "100%" }}
                title="PDF Viewer"
                src={pdf}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default ProgressReport1;